import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Alert } from '@mui/material';
import {useAuth} from '../context/AuthContext';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleEmailLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    const apiUrl = process.env.REACT_APP_BACK_END_URL || window.location.origin;
    console.log('APP: handleEmailLogin: apiUrl:', apiUrl,  'env', process.env.REACT_APP_BACK_END_URL);
    try {
      const response = await fetch(`${apiUrl}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        credentials: 'include',
      });
      const result = await response.json();
      if (result.success) {
        console.log('Login successful, navigating to /dashboard');
        login(result.user); // Update authentication status
        navigate('/dashboard?status=success'); // Use replace option
      } else {
        console.log('Invalid credentials');
        setError('Invalid credentials');
      }
    } catch (err) {
      console.error('An error occurred. Please try again.', err);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <Box component="form" onSubmit={handleEmailLogin} sx={{ mt: 2 }}>
      <TextField
        label="Email"
        type="email"
        autoComplete="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Password"
        type="password"
        autoComplete="current-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
      />
      {error && <Alert severity="error">{error}</Alert>}
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        Login with Email
      </Button>
    </Box>
  );
};

export default LoginForm;
