import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  IconButton,
  Divider,
  TextField,
  FormControl,
  FormLabel,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { areas } from "./areas";
import { QuoteIntake, TreatmentArea } from "./types";
import EstimatedPrice from "./EstimatedPrice";
import { calculatePriceAndFees } from "./calculatePrice";
import { postQuoteData } from "./utils";
import { GeneratePDFParams } from "./types";
interface PriceEstimatorProps {
  onNext: (params: QuoteIntake) => void;
  quoteParams?: QuoteIntake | null;
}

const PriceEstimator: React.FC<PriceEstimatorProps> = ({
  onNext,
  quoteParams,
}) => {
  const initializeSelectedAreas = () => {
    if (!quoteParams) return {};

    const { costDetails } =
      calculatePriceAndFees(quoteParams.selectedAreas) || {};
    
      return costDetails.reduce((acc, detail) => {
      const type = Object.keys(areas).find((key) =>
        areas[key].some((area) => area.english === detail.area)
      );
      if (type) {
        const area = areas[type].find((area) => area.english === detail.area);
        if (area) {
          acc[detail.area] = { ...area, sessions: detail.sessions, type };
        }
      }
      return acc;
    }, {} as Record<string, TreatmentArea & { type: string }>);
  };

  const initializePaymentPlans = () => {
    if (!quoteParams) return { 6: false, 9: false, 12: false };
    return {
      6: quoteParams.selectedPaymentPlans.includes(6),
      9: quoteParams.selectedPaymentPlans.includes(9),
      12: quoteParams.selectedPaymentPlans.includes(12),
    };
  };

  const [selectedAreas, setSelectedAreas] = useState<
    Record<string, TreatmentArea & { type: string }>
  >(initializeSelectedAreas);
  const [patientName, setPatientName] = useState<string>(
    quoteParams?.patientName || ""
  );
  const [paymentPlans, setPaymentPlans] = useState<{ [key: number]: boolean }>(
    initializePaymentPlans()
  );

  const handleCheckboxChange = (area: TreatmentArea, type: string) => {
    const defaultSessions = type === "small" ? 6 : 8; // Default sessions: 6 for small areas, 8 for others
    setSelectedAreas((prevSelectedAreas) => {
      if (prevSelectedAreas[area.english]) {
        const updatedAreas = { ...prevSelectedAreas };
        delete updatedAreas[area.english];
        return updatedAreas;
      } else {
        return {
          ...prevSelectedAreas,
          [area.english]: { ...area, sessions: defaultSessions, type },
        };
      }
    });
  };

  const handleSessionChange = (areaEnglish: string, increment: number) => {
    setSelectedAreas((prevSelectedAreas) => {
      const updatedSessions =
        (prevSelectedAreas[areaEnglish].sessions || 1) + increment;
      return {
        ...prevSelectedAreas,
        [areaEnglish]: {
          ...prevSelectedAreas[areaEnglish],
          sessions: updatedSessions > 0 ? updatedSessions : 1,
        },
      };
    });
  };

  const handlePaymentPlanChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentPlans({
      ...paymentPlans,
      [parseInt(event.target.name)]: event.target.checked,
    });
  };

  const generateQuote = () => {
    // make a call to server to save quote

    const {
      totalCost,
      totalSavings,
      costDetails,
      processingFee,
      totalWithFee,
    } = calculatePriceAndFees(selectedAreas);

    const quoteData: GeneratePDFParams = {
      idempotencyKey: patientName.toLowerCase().replace(/\s/g, ""),
      patientName,
      totalCost: totalCost || 0,
      totalSavings: totalSavings || 0,
      costDetails,
      processingFee: processingFee || 0,
      totalWithFee: totalWithFee || 0,
      selectedPaymentPlans: [],
      selectedAreas,
    };

    postQuoteData(quoteData);


    const selectedPaymentPlans = Object.keys(paymentPlans)
      .filter((key) => paymentPlans[parseInt(key)])
      .map(Number);
    onNext({
      patientName,
      selectedAreas,
      selectedPaymentPlans,
    });
  };

  return (
    <Box p={2}>
      <Box mt={2}>
        <TextField
          label="Patient Name"
          value={patientName}
          onChange={(e) => setPatientName(e.target.value)}
          variant="outlined"
        />
      </Box>
      <FormGroup>
        {Object.keys(areas).flatMap((type) =>
          areas[type].map((area: TreatmentArea) => (
            <div key={area.english}>
              <Grid
                container
                alignItems="center"
                spacing={2}
                className="area-item"
              >
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!selectedAreas[area.english]}
                        onChange={() => handleCheckboxChange(area, type)}
                      />
                    }
                    label={`${type.charAt(0).toUpperCase() + type.slice(1)} | ${
                      area.english
                    } | ${area.portuguese}`}
                    style={{ textAlign: "left", width: "100%" }}
                  />
                </Grid>
                {selectedAreas[area.english] && (
                  <Grid item>
                    <Box
                      sx={{ display: "none" }}
                      display="flex"
                      alignItems="center"
                      className="session-control"
                    >
                      <IconButton
                        onClick={() => handleSessionChange(area.english, -1)}
                      >
                        <Remove />
                      </IconButton>
                      <Typography variant="body1" mx={2}>
                        {selectedAreas[area.english].sessions}
                      </Typography>
                      <IconButton
                        onClick={() => handleSessionChange(area.english, 1)}
                      >
                        <Add />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Divider />
            </div>
          ))
        )}
      </FormGroup>
      {Object.keys(selectedAreas).length > 0 && true && (
        <Box mt={2}>
          <Typography variant="h5">Selected Areas</Typography>
          {Object.values(selectedAreas).map((area) => (
            <div key={area.english}>
              <Grid
                container
                alignItems="center"
                spacing={2}
                className="selected-area-item"
              >
                <Grid item xs={12} sm={6}>
                  {area.english} ({area.portuguese})
                </Grid>
                <Grid item>
                  <Box
                    display="flex"
                    alignItems="center"
                    className="session-control"
                  >
                    <IconButton
                      onClick={() => handleSessionChange(area.english, -1)}
                    >
                      <Remove />
                    </IconButton>
                    <Typography variant="body1" mx={2}>
                      {selectedAreas[area.english].sessions}
                    </Typography>
                    <IconButton
                      onClick={() => handleSessionChange(area.english, 1)}
                    >
                      <Add />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleCheckboxChange(area, area.type)}
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
              <Divider />
            </div>
          ))}
        </Box>
      )}

      {/* hide div below */}
      <Box mt={2} style={{ display: "none" }}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Select Payment Plans to show in estimate
          </FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={paymentPlans[6]}
                  onChange={handlePaymentPlanChange}
                  name="6"
                />
              }
              label="6 months"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={paymentPlans[9]}
                  onChange={handlePaymentPlanChange}
                  name="9"
                />
              }
              label="9 months"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={paymentPlans[12]}
                  onChange={handlePaymentPlanChange}
                  name="12"
                />
              }
              label="12 months"
            />
          </FormGroup>
        </FormControl>
      </Box>
      {Object.keys(selectedAreas).length > 0 && false && (
        <EstimatedPrice
          patientName={patientName}
          selectedAreas={selectedAreas}
          selectedPaymentPlans={Object.keys(paymentPlans).map(Number)}
        />
      )}
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={generateQuote}>
          Generate Quote
        </Button>
      </Box>
    </Box>
  );
};

export default PriceEstimator;
