import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from "../../assets/chanramos-medspa-logo-with-name_horizontal-650x125.png";
import { GeneratePDFParams } from "./types";

const generatePDF = ({
  patientName,
  totalCost,
  totalSavings,
  costDetails,
  processingFee,
  totalWithFee,
  selectedPaymentPlans,
}: GeneratePDFParams) => {
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "letter",
  });
  const today = new Date().toLocaleDateString();

  // Add the logo
  const img = new Image();
  img.src = logo;
  img.onload = () => {
    doc.addImage(img, "PNG", 14, 10, 50, 10);

    doc.setFontSize(14);
    doc.text("Laser Hair Removal Treatment Plan", 14, 30);

    doc.setFontSize(10);
    doc.text(`Date: ${today}`, 14, 40);
    doc.text(`Patient Name: ${patientName}`, 14, 50);

    const tableColumn = [
      "Area",
      "Price per Session",
      "Sessions",
      "Retail Price",
      "Savings",
      "Package Price",
    ];
    const tableRows: (string | number)[][] = [];

    let totalRetailPrice = 0;
    let totalPackagePrice = 0;

    costDetails.forEach((detail) => {
      const retailPrice = (Number(detail.regularPricePerSession) * detail.sessions).toFixed(2);
      const packagePrice = (Number(detail.pricePerSession) * detail.sessions).toFixed(2);
      const savings = (parseFloat(retailPrice) - parseFloat(packagePrice)).toFixed(2);
      totalRetailPrice += parseFloat(retailPrice);
      totalPackagePrice += parseFloat(packagePrice);
      const detailData = [
        detail.area,
        `$${Number(detail.regularPricePerSession).toFixed(2)}`,
        detail.sessions,
        `$${retailPrice}`,
        `$${savings}`,
        `$${packagePrice}`,
      ];
      tableRows.push(detailData);
    });

    const totalRow = [
      "Subtotal",
      "",
      "",
      `$${totalRetailPrice.toFixed(2)}`,
      `$${Number(totalSavings).toFixed(2)}`,
      `$${totalPackagePrice.toFixed(2)}`,
    ];
    tableRows.push(totalRow);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 60,
      styles: { fontSize: 10 },
      bodyStyles: { font: "helvetica", fontSize: 10 },
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: "auto" },
        2: { cellWidth: "auto" },
        3: { cellWidth: "auto" },
        4: { cellWidth: "auto" },
        5: { cellWidth: "auto" },
      },
      didParseCell: function (data: { row: { index: number; }; cell: { styles: { fontStyle: string; }; }; }) {
        if (data.row.index === tableRows.length - 1) {
          data.cell.styles.fontStyle = "normal"; // Remove bold from totals
        }
      },
    });

    const tableY = (doc as any).lastAutoTable.finalY + 10;

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");

    const xOffset = 110;
    const yOffset = tableY + 5;

    doc.text("Processing Fee (3%):", xOffset, yOffset);
    doc.text(`$${Number(processingFee).toFixed(2)}`, xOffset + 60, yOffset);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text("TOTAL", xOffset, yOffset + 10);
    doc.text(`$${Number(totalWithFee).toFixed(2)}`, xOffset + 60, yOffset + 10);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`Total Savings:`, xOffset, yOffset + 20);
    doc.text(`$${Number(totalSavings).toFixed(2)}`, xOffset + 60, yOffset + 20);

    if (selectedPaymentPlans.length > 0) {
      // sort in descending order
      const sortedSelectedPaymentPlans = selectedPaymentPlans.sort((a, b) => b - a);

      const paymentPlanYOffset = yOffset + 35;
      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text("Payment Plan Options", 64, paymentPlanYOffset);
   
      sortedSelectedPaymentPlans.forEach((plan, index) => {
        const monthlyPayment = (totalWithFee / plan).toFixed(2);
        const paymentPlanText = `- Pay $${monthlyPayment} over ${plan} months`;
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(paymentPlanText, 70, paymentPlanYOffset + 10 + index * 8);
      });

      // add "One time payment" option
      const oneTimePaymentText = `- One time payment of $${totalWithFee.toFixed(2)}`;
      doc.text(oneTimePaymentText, 70, paymentPlanYOffset + 10 + selectedPaymentPlans.length * 8);
    }

    // const savingsYOffset = yOffset + 45 + 20;
    // doc.setFontSize(12);
    // doc.setFont("helvetica", "bold");
    // doc.text(`Total Savings:`, xOffset, savingsYOffset + 20);
    // doc.text(`$${Number(totalSavings).toFixed(2)}`, xOffset + 60, savingsYOffset + 20);


    const pageHeight = 279.4;
    const bottomMargin = 12.7;
    const disclaimerYOffset = pageHeight - bottomMargin - 6 * 5;

    doc.setFontSize(7.5);
    doc.setFont("helvetica", "italic");
    const disclaimerText = [
      "THIS WORKSHEET IS FOR ILLUSTRATIVE PURPOSES ONLY AND DOES NOT CONSTITUTE AN OFFER, AN AGREEMENT, OR OBLIGATION TO",
      "OBTAIN OR PROVIDE SERVICES, OR ANY FINANCING AGREEMENT OR PAYMENT AGREEMENT FOR ANY SERVICES. IF YOU ELECT TO",
      "PURCHASE PRODUCTS OR SERVICES FROM CHAN RAMOS MEDSPA, THE TERMS OF YOUR PURCHASE WILL BE CLEARLY ARTICULATED IN AN",
      "AGREEMENT THAT YOU SHOULD REVIEW CAREFULLY BEFORE SIGNING. ANY AND ALL SUCH AGREEMENTS ARE SUBJECT TO ADDITIONAL",
      "DISCLOSURES, TERMS, AND CONDITIONS. ADDITIONALLY, THE AMOUNTS LISTED ON THIS WORKSHEET DO NOT INCLUDE ALL APPLICABLE",
      "TAXES AND FEES. PRICES OF SERVICES AND PRODUCTS ARE SUBJECT TO CHANGE AT THE COMPANY’S DISCRETION AT ANY TIME PRIOR TO",
    ];

    disclaimerText.forEach((line, index) => {
      doc.text(line, 14, disclaimerYOffset + index * 5);
    });

    doc.save("treatment-plan.pdf");
  };
};

export default generatePDF;
