import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import "./QuoteSummary.css";
import generatePDF from "./generatePDF";
import { QuoteIntake, CostDetail, GeneratePDFParams } from "./types";
import { calculatePriceAndFees } from "./calculatePrice";
import EstimatedPrice from "./EstimatedPrice";
import axios from "axios";
import { postQuoteData } from "./utils";
interface QuoteProps {
  quoteParams: QuoteIntake;
  onBack: () => void;
}

const QuoteSummary: React.FC<QuoteProps> = ({ quoteParams, onBack }) => {
  const { patientName, selectedAreas, selectedPaymentPlans } = quoteParams;

  const [totalCost, setTotalCost] = useState<number>(0);
  const [totalSavings, setTotalSavings] = useState<number>(0);
  const [costDetails, setCostDetails] = useState<CostDetail[]>([]);
  const [processingFee, setProcessingFee] = useState<number>(0);
  const [totalWithFee, setTotalWithFee] = useState<number>(0);

  useEffect(() => {
    const {
      totalCost,
      totalSavings,
      costDetails,
      processingFee,
      totalWithFee,
    } = calculatePriceAndFees(selectedAreas);
    setTotalCost(totalCost);
    setTotalSavings(totalSavings);
    setCostDetails(costDetails);
    setProcessingFee(processingFee);
    setTotalWithFee(totalWithFee);
  }, [selectedAreas]);

  const handleGeneratePDF = () => {
    const idempotencyKey = patientName.toLowerCase().replace(/\s/g, "");

    const quoteData: GeneratePDFParams= {
      idempotencyKey,
      patientName,
      totalCost: totalCost || 0,
      totalSavings: totalSavings || 0,
      costDetails,
      processingFee: processingFee || 0,
      totalWithFee: totalWithFee || 0,
      selectedPaymentPlans,
      selectedAreas,
    };

    // generate a unique hash from patientName, this is the idempotency key
    // to prevent duplicate entries in the database. make it lowercase and remove spaces
    generatePDF(quoteData);

    // postQuoteData(quoteData);

  };

  return (
    <Box p={2}>
      {/* <Typography variant="h4" gutterBottom>Laser Hair Removal Quote</Typography> */}
      <Box mt={2}>
        <Typography variant="h5">Quote Summary</Typography>
        <Typography>Patient Name: {patientName}</Typography>
        <EstimatedPrice
          patientName={patientName}
          selectedAreas={selectedAreas}
          selectedPaymentPlans={selectedPaymentPlans}
        />
      </Box>
      <Box mt={2}>
        <Button variant="text" color="secondary" onClick={onBack}>
          Edit Quote
        </Button>
        <Button variant="contained" color="primary" onClick={handleGeneratePDF}>
          Print PDF
        </Button>
      </Box>
    </Box>
  );
};

export default QuoteSummary;
