// HelpCenter.tsx
import React from "react";
import { Typography, Container, Box, List, ListItem, ListItemText } from "@mui/material";

const HelpCenter: React.FC = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: "40px" }}>
      <Typography variant="h4" gutterBottom>
        Help Center
      </Typography>
      <Typography variant="body1" paragraph>
        Find answers to common questions and learn more about our services.
      </Typography>

      <Box marginTop={4}>
        <Typography variant="h5" gutterBottom>
          Frequently Asked Questions
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="How do I create an account?"
              secondary="Click 'Sign Up' on the homepage and fill out the form."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="How do I reset my password?"
              secondary="Use the 'Forgot Password?' link on the login page."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Where are my account settings?"
              secondary="Go to 'Settings' by clicking your profile picture."
            />
          </ListItem>
        </List>
      </Box>

      <Box marginTop={4}>
        <Typography variant="h5" gutterBottom>
          Contact Support
        </Typography>
        <Typography variant="body1" paragraph>
          For assistance, visit <a href="https://chanramos.com" target="_blank" rel="noopener noreferrer">chanramos.com</a> to find our contact details.
        </Typography>
      </Box>
    </Container>
  );
};

export default HelpCenter;
