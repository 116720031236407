import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline, Box } from "@mui/material";
import { AuthProvider, useAuth } from "./context/AuthContext";
import AppRoutes from "./AppRoutes";
import Navigation from "./components/Navigation";

const App: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <CssBaseline />
      <Box sx={{ display: "flex" }}>
        <Navigation />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            padding: 3,
            marginTop: isAuthenticated ? { xs: 8, md: 0 } : 0,
            width: isAuthenticated ? { xs: "100%", md: `calc(100% - ${240}px)` } : "100%",
          }}
        >
          <AppRoutes />
        </Box>
      </Box>
    </Router>
  );
};

const AppWithProviders: React.FC = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default AppWithProviders;
