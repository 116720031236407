// create function to post quote data to backend

import axios from "axios";
import { GeneratePDFParams } from "./types";

async function postQuoteData(quoteData: GeneratePDFParams) {
  // console.log("Posting quote data:", quoteData);
  // return
  try {
    const response = await axios.post("/api/quote", quoteData);
    console.log(
      "Data logged successfully:",
      response.data.message || response.data
    );
  } catch (error) {
    console.error("Error logging data:", error);
  }
}

export { postQuoteData };