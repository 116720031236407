import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  calculatePriceAndFees,
  calculateMonthlyPayments,
} from "./calculatePrice";
import { TreatmentArea, CostDetail } from "./types";

interface EstimatedPriceProps {
  patientName: string;
  selectedAreas: Record<string, TreatmentArea & { type: string }>;
  selectedPaymentPlans: number[]; // Added selectedPaymentPlans
}

const EstimatedPrice: React.FC<EstimatedPriceProps> = ({
  patientName,
  selectedAreas,
  selectedPaymentPlans,
}) => {
  const { totalCost, totalSavings, costDetails, processingFee, totalWithFee } =
    calculatePriceAndFees(selectedAreas);
  const paymentPlanDetails = calculateMonthlyPayments(
    totalWithFee,
    selectedPaymentPlans
  ); // Calculate payment plans
  const [showDetails, setShowDetails] = useState<boolean>(true);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <Box mt={2} className="estimated-price">
      {showDetails && (
        <div>
          <Typography variant="h5">Estimated Price</Typography>
          <Typography>Total Estimated Cost: ${totalCost.toFixed(2)}</Typography>
          <Typography>
            Processing Fee (3%): ${processingFee.toFixed(2)}
          </Typography>
          <Typography>
            Total Cost with Fee: ${totalWithFee.toFixed(2)}
          </Typography>
          {/* <Typography>Total Savings: ${totalSavings.toFixed(2)}</Typography> */}

          <ul>
            {costDetails.map((detail) => (
              <li key={detail.area}>
                {detail.area}: ${detail.pricePerSession.toFixed(2)} x{" "}
                {detail.sessions} sessions = ${detail.areaTotal.toFixed(2)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </Box>
  );
};

export default EstimatedPrice;
