import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Container,
  IconButton,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
// Define the structure of a log entry
interface LogEntry {
  id: number;
  user_id: string;
  email: string;
  method: string;
  ip_address: string;
  timestamp: string;
}

const AccessLogs: React.FC = () => {
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalLogs, setTotalLogs] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const logsPerPage = 20;

  // Fetch logs from the API using Axios
  useEffect(() => {
    const fetchLogs = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get("/api/login-logs", {
          params: { page: currentPage, limit: logsPerPage },
        });

        const data = response.data;
        setLogs(data.logs);
        setTotalLogs(data.total);
      } catch (error) {
        setError("Failed to fetch logs");
        console.error("Failed to fetch logs", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLogs();
  }, [currentPage]);

  // Calculate total pages
  const totalPages = Math.ceil(totalLogs / logsPerPage);

  // Handle page change
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Handle delete logs
  const handleDeleteLogs = async () => {
    // add confirmation dialog to confirm deletion
    if (!window.confirm("Are you sure you want to delete all logs?")) {
      return;
    }
    
    try {
      await axios.delete("/api/login-logs");
      setLogs([]);
      setTotalLogs(0);
    } catch (error) {
      console.error("Failed to delete logs", error);
    }
  };

  return (
    <Container>
      <Typography variant="h6" gutterBottom>
        Access Logs
      </Typography>
      {/* add trach can to delete access logs */}
      <IconButton aria-label="delete" onClick={handleDeleteLogs}>
        <DeleteIcon />
      </IconButton>

      {loading ? (
        <Typography variant="body1">Loading...</Typography>
      ) : error ? (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Timestamp</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Method</TableCell>
                  <TableCell>IP Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((log) => (
                  <TableRow key={log.id}>
                    <TableCell>
                      {new Date(log.timestamp).toLocaleString()}
                    </TableCell>
                    <TableCell>{log.user_id}</TableCell>
                    <TableCell>{log.email}</TableCell>
                    <TableCell>{log.method}</TableCell>
                    <TableCell>{log.ip_address}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              style={{ marginRight: 8 }}
            >
              Previous
            </Button>
            <Typography variant="body1">
              Page {currentPage} of {totalPages}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              style={{ marginLeft: 8 }}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

export default AccessLogs;
