import React from 'react';
import { useNavigate } from 'react-router-dom';

const BusinessPage: React.FC = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/treatment-estimator');
  };

  return (
    <div>
      <h1>Business Admin Dashboard</h1>
      <button onClick={handleButtonClick}>Laser Estimate</button>
      {/* <button onClick={handleButtonClick}>EMS Estimate</button> */}

    </div>
  );
};

export default BusinessPage;
