// src/components/PlatformAdminDashboard.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Typography, TextField, List, ListItem, ListItemText } from '@mui/material';
import AdminBusinessList from './AdminBusinessList';
import AccessLogs from './AccessLogs';
import Quotes from './Quotes';
interface Business {
  id: number;
  name: string;
  email: string;
  phone: string;
}

const PlatformAdminPage: React.FC = () => {
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [selectedBusinessId, setSelectedBusinessId] = useState<number | null>(null);
  const [userId, setUserId] = useState<string>('');

  const apiUrl = process.env.REACT_APP_BACK_END_URL || window.location.origin;

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/businesses`, { withCredentials: true });
        setBusinesses(response.data);
      } catch (error) {
        console.error('Error fetching businesses', error);
      }
    };

    fetchBusinesses();
  }, []);

  const handleAssignUser = async () => {
    if (!selectedBusinessId || !userId) {
      alert('Please select a business and enter a user ID');
      return;
    }

    try {
      await axios.post('/api/businesses/assign-staff', { businessId: selectedBusinessId, userId });
      alert('User assigned to business successfully');
      setUserId('');
      setSelectedBusinessId(null);
    } catch (error) {
      console.error('Error assigning user to business', error);
      alert('Failed to assign user to business');
    }
  };

  return (
    <div>
      <Typography variant="h4">Platform Admin Dashboard</Typography>
      <Quotes />
      <AccessLogs />
      <AdminBusinessList />
    </div>
  );
};

export default PlatformAdminPage;
