import React from 'react';

const PatientPortalPage: React.FC = () => {
  return (
    <div>
      <h1>Patient Portal Dashboard</h1>
      {/* Add components like AppointmentBooking, TreatmentHistory, etc. */}
    </div>
  );
};

export default PatientPortalPage;
