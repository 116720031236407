import { TreatmentArea, CostDetail } from './types';

// const discounts = [0.167, 0.3061, 0.422];
const discounts = [0,0,0];

export const calculatePriceAndFees = (selectedAreas: Record<string, TreatmentArea & { type: string }>): {
  totalCost: number;
  totalSavings: number;
  costDetails: CostDetail[];
  processingFee: number;
  totalWithFee: number;
} => {
  let total = 0;
  let savings = 0;
  const details: CostDetail[] = [];

  const sortedAreas = Object.values(selectedAreas).sort((a, b) => b.price - a.price);

  sortedAreas.forEach((area, index) => {
    const price = area.price;
    const sessionCount = area.sessions || 1;
    let discount = 0;

    if (sessionCount >= 6) {
      if (index < 3) {
        discount = discounts[index];
      } else {
        discount = discounts[discounts.length - 1];
      }
    }

    const regularPricePerSession = price;
    const pricePerSession = price * (1 - discount);
    const areaTotal = pricePerSession * sessionCount;
    const regularAreaTotal = regularPricePerSession * sessionCount;
    const areaSavings = regularAreaTotal - areaTotal;
    total += areaTotal;
    savings += areaSavings;

    details.push({
      area: area.english,
      sessions: sessionCount,
      regularPricePerSession: regularPricePerSession,
      discount: (discount * 100).toFixed(2) + "%",
      pricePerSession: pricePerSession,
      areaTotal: areaTotal,
      regularAreaTotal: regularAreaTotal,
      areaSavings: areaSavings,
    });
  });

  const processingFee = total * 0.03;
  const totalWithFee = total + processingFee;

  return { totalCost: total, totalSavings: savings, costDetails: details, processingFee, totalWithFee };
};

export const calculateMonthlyPayments = (
  totalWithFee: number,
  selectedPaymentPlans: number[]
): { duration: string; monthlyPayment: string }[] => {
  return selectedPaymentPlans.map(plan => {
    const monthlyPayment = (totalWithFee / plan).toFixed(2);
    return { duration: `${plan} months`, monthlyPayment: `$${monthlyPayment}` };
  });
};
