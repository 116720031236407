// AppRoutes.tsx or at the bottom of App.tsx
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import Home from "./pages/Home";
import PlatformAdminPage from "./components/Platform/PlatformAdminPage";
import BusinessAdminPage from "./components/Business/BusinessAdminPage";
import PatientPortalPage from "./components/PatientPortal/PatientPortalPage";
import Dashboard from "./pages/Dashboard";
import Business from "./components/Business/BusinessAdminPage";
import Settings from "./pages/Settings"; // Import the new Settings component
import HelpCenter from "./pages/HelpCenter";
import AdminBusinessDetails from "./components/Platform/AdminBusinessDetails";
import NotFound from "./pages/NotFound";
import AccessDenied from "./pages/AccessDenied";

import QuoteLHR from "./components/TreatmentEstimator/QuoteLHR/QuoteLHR";
import TreatmentEstimator from "./components/TreatmentEstimator";

const AppRoutes: React.FC = () => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    localStorage.setItem("intendedRoute", window.location.pathname);
  }
  let isAdmin: boolean = false;
  if (isAuthenticated) {
    // if roles has key 'platform' and one of the vaules in array is 'admin' then isAdmin is true
    isAdmin = Boolean(user?.roles?.platform?.includes("admin"));
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/quote/laser" element={<QuoteLHR />} />
      <Route
        path="/dashboard"
        element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
      />
      <Route
        path="/treatment-estimator"
        element={isAuthenticated ? <TreatmentEstimator /> : <Navigate to="/" />}
      />
      <Route
        path="/business-admin"
        element={isAuthenticated ? <Business /> : <Navigate to="/" />}
      />
      <Route
        path="/patient-portal"
        element={isAuthenticated ? <PatientPortalPage /> : <Navigate to="/" />}
      />
      <Route
        path="/settings"
        element={isAuthenticated ? <Settings /> : <Navigate to="/" />}
      />
      <Route
        path="/help-center"
        element={isAuthenticated ? <HelpCenter /> : <Navigate to="/" />}
      />
      <Route
        path="/platform-admin"
        element={isAdmin ? <PlatformAdminPage /> : <AccessDenied />}
      />
      <Route
        path="/platform-admin/business/:businessId"
        element={isAdmin ? <AdminBusinessDetails /> : <Navigate to="/" />}
      />
      {/* Catch-all route for 404 page */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
