// AccessDenied.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock'; // Importing Material UI lock icon

// handle on click event to navigate to home page
import { Navigate } from 'react-router-dom';

const handleOnClick = () => {
  localStorage.removeItem('intendedRoute');
  return <Navigate to="/" />;
}
const AccessDenied: React.FC = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <LockIcon style={{ fontSize: 60, color: '#ff5722' }} />
      <h1>Access Denied</h1>
      <p>You do not have permission to view this page.</p>
      
      <Link to="/" onClick={handleOnClick}>Go to Home</Link>
      
    </div>
  );
};

export default AccessDenied;
