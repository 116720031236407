import React from 'react';
import { Container, Card, CardContent, Typography, Divider } from '@mui/material';

const Disclaimer = () => {
  return (
    <Container>
      <Card>
        <CardContent>
          <Typography variant="h6" component="h2" gutterBottom>
            Laser Hair Removal Estimator Disclaimer
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1" paragraph>
            Welcome to the Laser Hair Removal Estimator!
          </Typography>
          <Typography variant="body1" paragraph>
            This tool is designed to provide an approximate estimate of the cost and number of sessions required for laser hair removal based on the areas you wish to treat and your skin type. Please note that this estimate is intended for informational purposes only and is not a guarantee of specific results or pricing.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Important Points to Consider:</strong>
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>1. Individual Variability:</strong> The actual number of sessions required and final cost may vary depending on individual factors such as hair color, hair density, skin type, and personal response to treatment.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>2. Consultation Required:</strong> For an accurate assessment and personalized treatment plan, a consultation with one of our licensed professionals is necessary. They will evaluate your unique needs and provide a detailed quote.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>3. Treatment Results:</strong> Results can vary from person to person. This estimator does not account for potential side effects or the need for additional treatments.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>4. Pricing Fluctuations:</strong> Pricing may change based on promotional offers, package deals, and other factors. This estimator provides a general estimate, which may not reflect current pricing.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>5. Medical Advice:</strong> This tool does not substitute for medical advice. Always consult with a healthcare provider or a licensed practitioner before starting any new treatment.
          </Typography>
          <Typography variant="body1" paragraph>
            By using this estimator, you acknowledge that the information provided is an estimate and that we cannot guarantee specific outcomes or costs.
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions or need further assistance, please contact us directly.
          </Typography>
          <Typography variant="body1" paragraph>
            Thank you for using our Laser Hair Removal Estimator!
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Disclaimer;
