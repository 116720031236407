import { User } from '../context/AuthContext';
import { Role } from '../types/roles';

export const hasRole = (user: User | null, role: string): boolean => {
  // console.log('hasRole:', user, role);
  if (!user) {
    console.error('hasRole: User is null or undefined');
    return false;
  }

  if (!role) {
    console.error('hasRole: Role is missing or empty');
    return false;
  }

  try {
    return Object.values(user.roles || {}).some(roles => roles.includes(role as Role));
  } catch (error) {
    console.error('hasRole: Error checking roles', error);
    return false;
  }
};

export const hasBusinessRole = (user: User | null, businessId: string, role: string): boolean => {
  // console.log('hasBusinessRole:', user, businessId, role);
  if (!user) {
    console.error('hasBusinessRole: User is null or undefined');
    return false;
  }

  if (!businessId) {
    console.error('hasBusinessRole: Business ID is missing or empty');
    return false;
  }

  if (!role) {
    console.error('hasBusinessRole: Role is missing or empty');
    return false;
  }

  try {
    return user.roles[businessId]?.includes(role as Role) || false;
  } catch (error) {
    console.error('hasBusinessRole: Error checking business roles', error);
    return false;
  }
};
