import React, { useState } from 'react';
import { Box, Typography, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import AreasIntake from '../AreasIntake';
import SkinTypeForm from '../SkinTypeForm';  // Import SkinTypeForm
import Disclaimer from './Disclaimer';  // Import Disclaimer

const QuoteLHR: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [agreed, setAgreed] = useState(false);
  const [selectedAreas, setSelectedAreas] = useState({});
  const [skinTypeData, setSkinTypeData] = useState({ skinType: '', hairColor: '', hairThickness: '' });
  const [contactInfo, setContactInfo] = useState({ name: '', phone: '', email: '' });

  const handleNextStep = (data?: any) => {
    if (currentStep === 1 && Object.keys(selectedAreas).length === 0) {
      alert("Please select at least one area.");
      return;
    }
    if (currentStep === 2 && (!data.skinType || !data.hairColor || !data.hairThickness)) {
      alert("Please select your skin type, hair color, and hair thickness.");
      return;
    }
    if (currentStep === 3 && (!contactInfo.name || !contactInfo.phone || !contactInfo.email)) {
      alert("Please fill out all contact information fields.");
      return;
    }
    if (currentStep === 2 && data) {
      setSkinTypeData(data);
    }
    setCurrentStep(currentStep + 1);

    console.log('Current Step:', currentStep, 'data:', {selectedAreas, skinTypeData, contactInfo});
    if(currentStep === 3) {
      console.log('POST to server');
    }
  };

  const handleBackStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleStartOver = () => {
    setCurrentStep(0);
    setAgreed(false);
    setSelectedAreas({});
    setSkinTypeData({ skinType: '', hairColor: '', hairThickness: '' });
    setContactInfo({ name: '', phone: '', email: '' });
  };

  const handleAreasChange = (areas: any) => {
    setSelectedAreas(areas);
  };

  const handleContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <Box>
            <Typography variant="h5" gutterBottom>
              Welcome to the Laser Hair Removal Estimator
            </Typography>
            <Disclaimer />
            <FormControlLabel
              control={<Checkbox checked={agreed} onChange={() => setAgreed(!agreed)} />}
              label="I understand how this estimator works."
            />
            <Button variant="contained" color="primary" onClick={handleNextStep} disabled={!agreed}>
              Get Started
            </Button>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="h5" gutterBottom>
              Step 1: Select Areas
            </Typography>
            <AreasIntake onChange={handleAreasChange} hideSessionControls={true} />
            <Button variant="contained" color="primary" onClick={handleNextStep} sx={{ mt: 2 }}>
              Next Step
            </Button>
            <Button variant="text" color="secondary" onClick={handleBackStep} sx={{ mt: 2, ml: 2 }}>
              Back
            </Button>
          </Box>
        );
      case 2:
        return (
          <Box>
            <SkinTypeForm onNext={handleNextStep} />
            <Button variant="text" color="secondary" onClick={handleBackStep} sx={{ mt: 2, ml: 2 }}>
              Back
            </Button>
          </Box>
        );
      case 3:
        return (
          <Box>
            <Typography variant="h5" gutterBottom>
              Step 3: Your Contact Information
            </Typography>
            <TextField
              label="Name"
              name="name"
              value={contactInfo.name}
              onChange={handleContactChange}
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <TextField
              label="Phone"
              name="phone"
              value={contactInfo.phone}
              onChange={handleContactChange}
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <TextField
              label="Email"
              name="email"
              value={contactInfo.email}
              onChange={handleContactChange}
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <Button variant="contained" color="primary" onClick={handleNextStep}>
              Submit
            </Button>
            <Button variant="text" color="secondary" onClick={handleBackStep} sx={{ mt: 2, ml: 2 }}>
              Back
            </Button>
          </Box>
        );
      case 4:
        return (
          <Box>
            <Typography variant="h5" gutterBottom>
              Thank You!
            </Typography>
            <Typography paragraph>
              Your quote has been generated and will be sent to your email shortly.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleStartOver}>
              Start Over
            </Button>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Laser Hair Removal Estimator
      </Typography>
      {renderStep()}
    </Box>
  );
};

export default QuoteLHR;
