import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';

export type Roles = { [key: string]: string[] }; // Key: Business ID or 'platform', Value: Roles array
export interface User {
  id?: string;
  name: string;
  picture: string;
  roles: Roles;
}

interface AuthContextProps {
  isAuthenticated: boolean;
  user: User | null;
  loading: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  login: (user: User) => void;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkAuthStatus = async () => {
      const apiUrl = process.env.REACT_APP_BACK_END_URL || window.location.origin;
      const statusUrl = `${apiUrl}/api/auth/status`;
      // console.log('[AuthContext] Get auth status:', statusUrl, ' currentUrl:', currentUrl);
      try {
        const response = await fetch(statusUrl, {
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          // console.log('[AuthContext] isAuthenticated. Details:', data);
          setIsAuthenticated(data.isAuthenticated);
          setUser(data.user || null);
        } else {
          console.error('[AuthContext] Error fetching authentication status:', response);
          setIsAuthenticated(false);
          setUser(null);
        }
      } catch (error) {
        console.error('[AuthContext] Error fetching authentication status:', error);
        setIsAuthenticated(false);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    checkAuthStatus();
  }, []); // Ensure the dependency array is empty to run only once on mount

  const login = (user: User) => {
    setIsAuthenticated(true);
    setUser(user);
    if( !user.id ) {
      console.error('[AuthContext] User ID not set:', user);
    }
  };

  const logout = async () => {
    const apiUrl = process.env.REACT_APP_BACK_END_URL || window.location.origin;
    try {
      await fetch(`${apiUrl}/api/logout`, {
        method: 'GET',
        credentials: 'include',
      });
    } catch (error) {
      console.error('Error during logout:', error);
    }
    setIsAuthenticated(false);
    setUser(null);
    console.log('[AuthContext] User logged out');
    window.location.href = '/';
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, loading, setIsAuthenticated, setUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
