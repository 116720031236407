import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  Alert,
  Button,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface Business {
  id: number;
  name: string;
  email: string;
  phone: string;
  subscription_status: string;
}

const AdminBusinessList: React.FC = () => {
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBusinesses = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/api/businesses');
        setBusinesses(response.data);
      } catch (err) {
        setError('Failed to load businesses');
        console.error('Error fetching businesses:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinesses();
  }, []);

  const handleManageBusiness = (businessId: number) => {
    navigate(`/platform-admin/business/${businessId}`);
  };

  return (
    <Container>
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}

      {!loading && !error && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6">Businesses</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Subscription Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {businesses.map((business) => (
                <TableRow key={business.id}>
                  <TableCell>{business.id}</TableCell>
                  <TableCell>{business.name}</TableCell>
                  <TableCell>{business.email}</TableCell>
                  <TableCell>{business.phone}</TableCell>
                  <TableCell>{business.subscription_status}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => handleManageBusiness(business.id)}
                    >
                      Manage
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Container>
  );
};

export default AdminBusinessList;
