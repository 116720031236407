export enum Role {
  Admin = "admin",
  Staff = "staff",
  Customer = "customer",
  Owner = "owner",
  Manager = "manager",
  Receptionist = "receptionist",
  Provider = "provider",
  // Add other roles as needed
}

export type Roles = {
  [businessId: string]: Role[];
};
