import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import BusinessIcon from "@mui/icons-material/Business";
import HelpIcon from "@mui/icons-material/Help";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const drawerWidth = 240;
const contractedWidth = 60;

const Navigation: React.FC = () => {
  const { isAuthenticated, user, logout } = useAuth();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    if (isMobile) {
      setMobileOpen(!mobileOpen);
    } else {
      setExpanded(!expanded);
    }
  };

  return (
    <>
      {isAuthenticated && isMobile && (
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Chan Ramos MedSpa
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      {isAuthenticated && (
        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          open={isMobile ? mobileOpen : expanded}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            width: isMobile
              ? drawerWidth
              : expanded
              ? drawerWidth
              : contractedWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: isMobile
                ? drawerWidth
                : expanded
                ? drawerWidth
                : contractedWidth,
              boxSizing: "border-box",
              overflowX: "hidden",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
            }}
          >
            {expanded && (
              <Typography variant="h6">Chan Ramos MedSpa</Typography>
            )}
            <IconButton onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          </Box>
          <Divider />
          <List>
            <ListItem
              component={Link}
              to="/calendar"
              onClick={() => {
                if (isMobile) setMobileOpen(false);
              }}
              sx={{
                color: "inherit",
                display: "none", // Hide the Calendar link fow now
                textDecoration: "none",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <ListItemIcon>
                <CalendarTodayIcon />
              </ListItemIcon>
              {expanded && <ListItemText primary="Calendar" />}
            </ListItem>
            <ListItem
              component={Link}
              to="/dashboard"
              onClick={() => {
                if (isMobile) setMobileOpen(false);
              }}
              sx={{
                color: "inherit",
                textDecoration: "none",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              {expanded && <ListItemText primary="Dashboard" />}
            </ListItem>
            <ListItem
              component={Link}
              to="/clients"
              onClick={() => {
                if (isMobile) setMobileOpen(false);
              }}
              sx={{
                color: "inherit",
                display: "none", // Hide the Clients link fow now
                textDecoration: "none",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              {expanded && <ListItemText primary="Clients" />}
            </ListItem>
            <ListItem
              component={Link}
              to="/staff"
              sx={{
                color: "inherit",
                display: "none", // Hide the Staff link fow now
                textDecoration: "none",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              {expanded && <ListItemText primary="Staff" />}
            </ListItem>
            <ListItem
              component={Link}
              to="/settings"
              onClick={() => {
                if (isMobile) setMobileOpen(false);
              }}
              sx={{
                color: "inherit",
                textDecoration: "none",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              {expanded && <ListItemText primary="Settings" />}
            </ListItem>
          </List>
          <Box sx={{ flexGrow: 1 }} />
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <Avatar alt={user?.name} src={user?.picture} />
              </ListItemIcon>
              {expanded && <ListItemText primary={user?.name} />}
            </ListItem>
            <ListItem
              component={Link}
              to="/help-center"
              sx={{
                color: "inherit",
                textDecoration: "none",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              {expanded && <ListItemText primary="Help Center" />}
            </ListItem>
            <ListItem
              button
              onClick={logout}
              sx={{
                color: "inherit",
                textDecoration: "none",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              {expanded && <ListItemText primary="Logout" />}
            </ListItem>
          </List>
        </Drawer>
      )}
    </>
  );
};

export default Navigation;
