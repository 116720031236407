import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { areas } from "./areas";
import { TreatmentArea } from "./types";

interface AreasIntakeProps {
  onChange: (selectedAreas: Record<string, TreatmentArea & { type: string }>) => void;
  initialSelectedAreas?: Record<string, TreatmentArea & { type: string }>;
  hideSessionControls?: boolean;  // Prop to hide session controls
}

const AreasIntake: React.FC<AreasIntakeProps> = ({
  onChange,
  initialSelectedAreas = {},
  hideSessionControls = false,  // Default is false
}) => {
  const [selectedAreas, setSelectedAreas] = useState<
    Record<string, TreatmentArea & { type: string }>
  >(initialSelectedAreas);

  const handleCheckboxChange = (area: TreatmentArea, type: string) => {
    const defaultSessions = type === "small" ? 6 : 8; // Default sessions: 6 for small areas, 8 for others
    setSelectedAreas((prevSelectedAreas) => {
      if (prevSelectedAreas[area.english]) {
        const updatedAreas = { ...prevSelectedAreas };
        delete updatedAreas[area.english];
        onChange(updatedAreas);
        return updatedAreas;
      } else {
        const updatedAreas = {
          ...prevSelectedAreas,
          [area.english]: { ...area, sessions: defaultSessions, type },
        };
        onChange(updatedAreas);
        return updatedAreas;
      }
    });
  };

  const handleSessionChange = (areaEnglish: string, increment: number) => {
    setSelectedAreas((prevSelectedAreas) => {
      const updatedSessions =
        (prevSelectedAreas[areaEnglish].sessions || 1) + increment;
      const updatedAreas = {
        ...prevSelectedAreas,
        [areaEnglish]: {
          ...prevSelectedAreas[areaEnglish],
          sessions: updatedSessions > 0 ? updatedSessions : 1,
        },
      };
      onChange(updatedAreas);
      return updatedAreas;
    });
  };

  return (
    <Box>
      <FormGroup>
        {Object.keys(areas).flatMap((type) =>
          areas[type].map((area: TreatmentArea) => (
            <div key={area.english}>
              <Grid
                container
                alignItems="center"
                spacing={2}
                className="area-item"
              >
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!selectedAreas[area.english]}
                        onChange={() => handleCheckboxChange(area, type)}
                      />
                    }
                    label={`${type.charAt(0).toUpperCase() + type.slice(1)} | ${
                      area.english
                    } | ${area.portuguese}`}
                    style={{ textAlign: "left", width: "100%" }}
                  />
                </Grid>
                {selectedAreas[area.english] && !hideSessionControls && (
                  <Grid item>
                    <Box
                      display="flex"
                      alignItems="center"
                      className="session-control"
                    >
                      <IconButton
                        onClick={() => handleSessionChange(area.english, -1)}
                      >
                        <Remove />
                      </IconButton>
                      <Typography variant="body1" mx={2}>
                        {selectedAreas[area.english].sessions}
                      </Typography>
                      <IconButton
                        onClick={() => handleSessionChange(area.english, 1)}
                      >
                        <Add />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Divider />
            </div>
          ))
        )}
      </FormGroup>
    </Box>
  );
};

export default AreasIntake;
