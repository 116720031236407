import React, { useState } from 'react';
import PriceEstimator from './PriceEstimator';
import QuoteSummary from './QuoteSummary';
import { QuoteIntake } from './types';

const TreatmentEstimator: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [quoteParams, setQuoteParams] = useState<QuoteIntake | undefined>(undefined);

  const handleNextStep = (params: QuoteIntake) => {
    setQuoteParams(params);
    setCurrentStep(2);
  };

  const handleBackStep = () => {
    setCurrentStep(1);
  };

  return (
    <div>
      <h2>Treatment Estimator</h2>
      {currentStep === 1 && <PriceEstimator onNext={handleNextStep} quoteParams={quoteParams} />}
      {currentStep === 2 && quoteParams && <QuoteSummary quoteParams={quoteParams} onBack={handleBackStep} />}
    </div>
  );
};

export default TreatmentEstimator;
