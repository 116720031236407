import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Button,
  Divider,
} from '@mui/material';
import axios from 'axios';

interface Business {
  id: number;
  name: string;
  email: string;
  phone: string;
  subscription_status: string;
  subscription_start_date: string;
  subscription_end_date: string;
  created_at: string;
  updated_at: string;
}

interface Facility {
  id: number;
  name: string;
  city: string;
}

interface Staff {
  id: number;
  name: string;
  role: string;
  email: string;
}

const AdminBusinessDetails: React.FC = () => {
  const { businessId } = useParams<{ businessId: string }>();
  const [business, setBusiness] = useState<Business | null>(null);
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [staff, setStaff] = useState<Staff[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      setLoading(true);
      try {
        const [businessResponse, facilitiesResponse, staffResponse] = await Promise.all([
          axios.get(`/api/businesses/${businessId}`),
          axios.get(`/api/facilities/business/${businessId}`),
          axios.get(`/api/business/${businessId}/staff`),
        ]);
        setBusiness(businessResponse.data);
        setFacilities(facilitiesResponse.data);
        setStaff(staffResponse.data);
      } catch (err) {
        setError('Failed to load business details');
        console.error('Error fetching business details:', err);
      } finally {
        setLoading(false);
      }
    };

    if (businessId) {
      fetchBusinessDetails();
    }
  }, [businessId]);

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Container>
      <Button onClick={() => navigate(-1)} variant="contained" sx={{ mb: 2 }}>
        Back to Platform Snapshot
      </Button>

      <Typography variant="h4" gutterBottom>
        Business Management
      </Typography>
      {business ? (
        <Box sx={{ mb: 4 }}>
          <Typography variant="body1"><strong>ID:</strong> {business.id}</Typography>
          <Typography variant="body1"><strong>Name:</strong> {business.name}</Typography>
          <Typography variant="body1"><strong>Email:</strong> {business.email}</Typography>
          <Typography variant="body1"><strong>Phone:</strong> {business.phone}</Typography>
          <Typography variant="body1"><strong>Subscription Status:</strong> {business.subscription_status}</Typography>
          <Typography variant="body1"><strong>Subscription Start Date:</strong> {business.subscription_start_date}</Typography>
          <Typography variant="body1"><strong>Subscription End Date:</strong> {business.subscription_end_date}</Typography>
          <Typography variant="body1"><strong>Created At:</strong> {new Date(business.created_at).toLocaleString()}</Typography>
          <Typography variant="body1"><strong>Updated At:</strong> {new Date(business.updated_at).toLocaleString()}</Typography>
        </Box>
      ) : (
        <Typography variant="body1">No business found.</Typography>
      )}

      {/* Facilities Section */}
      <Divider sx={{ my: 4 }} />
      <Typography variant="h6" gutterBottom>
        Facilities
      </Typography>
      {facilities.length > 0 ? (
        <Box sx={{ mb: 4 }}>
          {facilities.map((facility) => (
            <Typography key={facility.id} variant="body1">
              <strong>Name:</strong> {facility.name}, <strong>City:</strong> {facility.city}
            </Typography>
          ))}
        </Box>
      ) : (
        <Typography variant="body2">No facilities found for this business.</Typography>
      )}

      {/* Staff Section */}
      <Divider sx={{ my: 4 }} />
      <Typography variant="h6" gutterBottom>
        Staff
      </Typography>
      {staff.length > 0 ? (
        <Box sx={{ mb: 4 }}>
          {staff.map((member) => (
            <Typography key={member.id} variant="body1">
              <strong>Name:</strong> {member.name}, <strong>Role:</strong> {member.role}, <strong>Email:</strong> {member.email}
            </Typography>
          ))}
        </Box>
      ) : (
        <Typography variant="body2">No staff found for this business.</Typography>
      )}
    </Container>
  );
};

export default AdminBusinessDetails;
