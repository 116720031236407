import React, { useState } from "react";
import { Box, Typography, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const SkinTypeForm: React.FC<{ onNext: (factors: any) => void }> = ({ onNext }) => {
  const [skinType, setSkinType] = useState("");
  const [hairColor, setHairColor] = useState("");
  const [hairThickness, setHairThickness] = useState("");

  const handleNext = () => {
    const factors = { skinType, hairColor, hairThickness };
    onNext(factors); // Pass the collected factors to the next step
  };

  return (
    <Box p={2}>
      <Typography variant="h5" gutterBottom>
        Step 2: Factors Affecting LHR Sessions
      </Typography>

      <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
        <InputLabel id="skin-type-label">Skin Type</InputLabel>
        <Select
          labelId="skin-type-label"
          value={skinType}
          onChange={(e) => setSkinType(e.target.value)}
          label="Skin Type"
        >
          <MenuItem value="I">Type I - Pale white skin</MenuItem>
          <MenuItem value="II">Type II - White skin</MenuItem>
          <MenuItem value="III">Type III - Light brown skin</MenuItem>
          <MenuItem value="IV">Type IV - Moderate brown skin</MenuItem>
          <MenuItem value="V">Type V - Dark brown skin</MenuItem>
          <MenuItem value="VI">Type VI - Deeply pigmented dark brown to black skin</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
        <InputLabel id="hair-color-label">Hair Color</InputLabel>
        <Select
          labelId="hair-color-label"
          value={hairColor}
          onChange={(e) => setHairColor(e.target.value)}
          label="Hair Color"
        >
          <MenuItem value="black">Black</MenuItem>
          <MenuItem value="brown">Brown</MenuItem>
          <MenuItem value="blonde">Blonde</MenuItem>
          <MenuItem value="red">Red</MenuItem>
          <MenuItem value="grey_white">Grey/White</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
        <InputLabel id="hair-thickness-label">Hair Thickness</InputLabel>
        <Select
          labelId="hair-thickness-label"
          value={hairThickness}
          onChange={(e) => setHairThickness(e.target.value)}
          label="Hair Thickness"
        >
          <MenuItem value="fine">Fine</MenuItem>
          <MenuItem value="medium">Medium</MenuItem>
          <MenuItem value="coarse">Coarse</MenuItem>
        </Select>
      </FormControl>

      <Button variant="contained" color="primary" onClick={handleNext}>
        Next
      </Button>
    </Box>
  );
};

export default SkinTypeForm;
