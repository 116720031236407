import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Container,
  Collapse,
  IconButton,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import generatePDF from '../TreatmentEstimator/generatePDF';
import { Delete } from '@mui/icons-material';
// Define the structure of a quote entry
interface QuoteEntry {
  id: number;
  author: string;
  customer: string;
  created_at: string;
  total_cost: number;
  total_savings: number;
  quoteObject: any;
  costDetails: {
    area: string;
    sessions: number;
    pricePerSession: number;
    areaTotal: number;
  }[];
}

interface CostDetail {
  area: string;
  discount: string;
  sessions: number;
  areaTotal: number;
  areaSavings: number;
  pricePerSession: number;
  regularAreaTotal: number;
  regularPricePerSession: number;
}

const Quotes: React.FC = () => {
  const [quotes, setQuotes] = useState<QuoteEntry[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalQuotes, setTotalQuotes] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [expandedQuote, setExpandedQuote] = useState<number | null>(null);
  const quotesPerPage = 20;

  // Fetch quotes from the API using Axios
  useEffect(() => {
    const fetchQuotes = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get('/api/quotes', {
          params: { page: currentPage, limit: quotesPerPage },
        });

        const data = response.data;
        setQuotes(data.quotes);
        setTotalQuotes(data.total);
      } catch (error) {
        setError('Failed to fetch quotes');
        console.error('Failed to fetch quotes', error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuotes();
  }, [currentPage]);

  // Calculate total pages
  const totalPages = Math.ceil(totalQuotes / quotesPerPage);

  // Handle page change
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Handle expanding/collapsing a quote
  const handleExpandClick = (id: number) => {
    setExpandedQuote(expandedQuote === id ? null : id);
  };

  const handlePrint = (quote: QuoteEntry) => {
    console.log('Printing quote:', quote);

    generatePDF(quote.quoteObject);
  };

// handle delete a quote
  const handleDelete = (id: number) => {
    // show a confirmation dialog before deleting the quote
    if (!window.confirm('Are you sure you want to delete this quote?')) {
      return
    }
    console.log('Deleting quote:', id);
    axios.delete(`/api/quotes/${id}`)
      .then(() => {
        console.log('Quote deleted successfully');
        setQuotes(quotes.filter((quote) => quote.id !== id));
      })
      .catch((error) => {
        console.error('Failed to delete quote:', error);
      });
  };

  return (
    <Container>
      <Typography variant="h6" gutterBottom>
        Quotes
      </Typography>
      {loading ? (
        <Typography variant="body1">Loading...</Typography>
      ) : error ? (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Timestamp</TableCell>
                  <TableCell>Author</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Total Cost</TableCell>
                  <TableCell>Total Savings</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quotes.map((quote) => (
                  <React.Fragment key={quote.id}>
                    <TableRow>
                      <TableCell>
                        <IconButton onClick={() => handleExpandClick(quote.id)}>
                          {expandedQuote === quote.id ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </TableCell>
                      <TableCell>{new Date(quote.created_at).toLocaleString()}</TableCell>
                      <TableCell>{quote.author}</TableCell>
                      <TableCell>{quote.customer}</TableCell>
                      <TableCell>${quote.total_cost.toFixed(2)}</TableCell>
                      <TableCell>${quote.total_savings.toFixed(2)}</TableCell>
                      <TableCell>
                        <Button variant="contained" color="primary" onClick={() => handlePrint(quote)}>
                          Print Quote
                        </Button>
                        {/* create a delete button with a trash can icon */}
                        <IconButton onClick={() => handleDelete(quote.id)}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                        <Collapse in={expandedQuote === quote.id} timeout="auto" unmountOnExit>
                          <div style={{ margin: 16 }}>
                            <Typography variant="h6">Details</Typography>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Area</TableCell>
                                  <TableCell>Sessions</TableCell>
                                  <TableCell>Price Per Session</TableCell>
                                  <TableCell>Total</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(quote.quoteObject.costDetails || []).map((detail: CostDetail, index: number) => (
                                  <TableRow key={index}>
                                    <TableCell>{detail.area}</TableCell>
                                    <TableCell>{detail.sessions}</TableCell>
                                    <TableCell>${detail.pricePerSession.toFixed(2)}</TableCell>
                                    <TableCell>${detail.areaTotal.toFixed(2)}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ marginTop: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              style={{ marginRight: 8 }}
            >
              Previous
            </Button>
            <Typography variant="body1">
              Page {currentPage} of {totalPages}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              style={{ marginLeft: 8 }}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

export default Quotes;
