import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Container, Box, Link } from "@mui/material";
import { useAuth } from "../context/AuthContext";
import LoginForm from "../components/LoginForm";
import { Google as GoogleIcon } from '@mui/icons-material';

const Home: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      // check if there a redirect saved in localStorage
      const intendedRoute = localStorage.getItem("intendedRoute");
      const navigateToUrl = intendedRoute ? intendedRoute : '/dashboard?status=redirect';
      console.log('User is authenticated, redirecting to', navigateToUrl , intendedRoute ? 'intended route' : 'default route');
      // clear the redirect from localStorage
      localStorage.removeItem("intendedRoute");
      navigate(navigateToUrl);
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = (role: string) => {
    const apiUrl = process.env.REACT_APP_BACK_END_URL || window.location.origin;
    window.location.href = `${apiUrl}/auth/google?role=${role}`;
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 8,
          p: 4,
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: 'white',
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Hello again
        </Typography>
        <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
          Welcome back, you’ve been missed!
        </Typography>
  
        <Button
          variant="outlined"
          startIcon={<GoogleIcon />} // Replace with your Google icon component
          sx={{
            width: '100%',
            mb: 2,
            color: '#4285F4',
            borderColor: '#4285F4',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'rgba(66, 133, 244, 0.1)',
              borderColor: '#4285F4',
            },
          }}
          onClick={() => handleLogin('google')}
        >
          Sign in with Google
        </Button>
  
        <Typography variant="body2" align="center" color="textSecondary" mb={2}>
          Or
        </Typography>
  
        <LoginForm />
    
        {/* <Typography variant="body2" align="center" mt={2}>
          Need an account?{' '}
          <Button color="primary" onClick={() => console.log('Sign up clicked')}>
            Sign up
          </Button>
        </Typography> */}
      </Box>
    </Container>
  );
  
  
};

export default Home;
